<template>
  <v-dialog
    v-model="toggle"
    scrollable
    fullscreen 
    persistent
    transition="scroll-y-transition"
    v-visibility-change="visibilityChange"
  >
    <v-card 
      id="help"
      class="sheet"
      dark
    >
      <v-toolbar
        :elevation="chat.ticket==null ? toolbarElevation : 0"
        color="transparent"
        class="page-header flex-grow-0 flex-shrink-1"
      >
        <v-btn
          v-if="level==0"
          icon
          dark
          @click="close"
        >
          <v-icon>{{ icons.close }}</v-icon>
        </v-btn>
        <v-btn
          v-else
          icon
          dark
          :disabled="chat.loading"
          @click="goBack"
        >
          <v-icon>{{ icons.back }}</v-icon>
        </v-btn>

        <v-spacer />

        <v-toolbar-title class="title">
          {{ title }}
        </v-toolbar-title>

        <v-spacer />

        <v-progress-circular
          v-if="chat.loading"
          indeterminate
          width="2"
          size="24"
          color="secondary"
          class="ml-3"
        />
        <v-btn
          v-else
          icon
          dark
          disabled
          @click="openForm"
        >
          <!-- <v-icon v-if="level<3">{{ icons.send }}</v-icon> -->
        </v-btn>
      </v-toolbar>

      <v-card-text
        id="help-scroll"
        class="help-content content pa-0 flex-grow-1"
        v-scroll:#help-scroll="onScroll"
        ref="content"
      >
        <v-window
          v-model="level"
          touchless
          class="px-2"
        >
          <v-window-item>
            <v-card
              v-if="hasTickets&&!isPage&&!isBenefitSelected"
              outlined
              class="ticket-list transparent mx-1 my-2"
            >
              <v-list
                two-line
                subheader
                class="transparent"
              >
                <v-subheader class="text-overline px-3 secondary--text">Chamados abertos:</v-subheader>
                <v-list-item
                  v-for="(ticket, id) in openedTickets"
                  :key="'ticket'+id"
                  :disabled="disabled"
                  class="list-item py-0 mx-0 px-3"
                  @click="openTicket(id)"
                >
                  <v-list-item-avatar>
                    <v-img
                      :src="require('@/assets/help/img/driver-bot-avatar-72.png')"
                    />
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title  
                      class="mb-1 font-weight-medium"
                    >
                      {{ ticket.summary }}
                      <!-- {{ ticket.assignee | ticketAssignee }} -->
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ ticket.messages | ticketBrief(sections, ticket.section) }}
                    </v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-icon>
                    <v-icon>
                      {{ icons.arrow }}
                    </v-icon>
                  </v-list-item-icon>
                </v-list-item>
              </v-list>
            </v-card>

            <div 
              v-if="isPage"
              class="page"
              :style="{ 'height': 'calc(100vh - 56px)' }"
            >
              <events
                v-if="section=='events'||isEventSelected"
                ref="events"
                :tickets="tickets"
                class="fill-height"
                @report="openTicket"
              />
              <!-- pages -->
              <m-benefits
                v-if="!isBenefitsEmpty&&section=='benefits'||isBenefitSelected"
                :data="benefitsData"
                :cta="benefitsCta.text"
                :url="benefitsCta.url"
                :full="benefitsFull"
                :loading="loading"
                :disabled="disabled"
                class="mx-n2 fill-height"
                @select="toggleBenefit"
              />
            </div>

            <v-item-group
              v-else
              v-model="section"
              color="secondary"
              class="py-4 mx-0 row dense"
            >
              <m-alerts
                :data="alertsByPriority.low"
                class="pa-1 my-2"
                @open="({ section, item }) => toggleSection(section, item)"
              />
              <v-item 
                v-for="(s, key) in sectionList"
                :key="key"
                :value="key"
                :disabled="disabled"
                class="help-section"
                v-slot="{ active, toggle }"
              >
                <v-col
                  cols="6"
                  class="pa-1"
                >
                  <v-card
                    class="section-card"
                    :disabled="disabled"
                    @click="toggle"
                  >
                    <v-img
                      height="160"
                      :src="require('@/assets/help/img/'+key+'.jpg')"
                      gradient="rgba(90, 129, 237, 0.24) 0%, rgba(61, 75, 143, 0.4) 40%, rgba(38, 39, 44, 0.8) 86%"
                      class="white--text align-end"
                    >
                      <v-card-title class="title text-h6 text-overline pa-3">{{ s.title }}</v-card-title>
                    </v-img>
                  </v-card>
                </v-col>
              </v-item>
            </v-item-group>

            <p 
              v-if="user.auth.token!=null&&!isPage"
              class="caption text-center mt-6 mb-2"
            >
              <a class="text--secondary white--text pa-2" @click="toggleTos()">Termos de Serviço</a> | <a class="text--secondary white--text pa-2" @click="togglePrivacy()">Política de Privacidade</a> 
            </p>
            <p 
              v-if="!isPage"
              class="text-caption grey--text text--darken-2 text-center mt-4 mb-6"
            >
              Versão do app: <b>{{ appversion }}</b>
            </p>
          </v-window-item>

          <v-window-item>
            <v-list
              v-if="section!=null&&sections[section].type=='info'"
              two-line
              class="transparent pb-8"
            >
              <v-list-item-group
                v-model="selected"
                :ripple="false"
                color="secondary"
              >
                <!-- <template v-for="(item, index) in items"> -->
                <v-list-item
                  v-for="(i, key) in items"
                  :key="key"
                  :value="key"
                  class="list-item py-2 pr-2"
                >
                  <v-list-item-content>
                    <v-list-item-title 
                      v-text="i.title" 
                      class="item-title"
                    />
                    <v-list-item-subtitle v-text="cleanText(i.text)" />
                  </v-list-item-content>

                  <v-list-item-icon>
                    <v-icon color="grey lighten-1">{{ icons.arrow }}</v-icon>
                  </v-list-item-icon>
                </v-list-item>

                <!-- <v-divider /> -->
              </v-list-item-group>
            </v-list>

            <m-chatbot
              v-else-if="section!=null&&sections[section].type=='issue'"
              :script="script"
              :user="user"
              :info="supportData"
              :ticket="chat.ticket"
              :tickets="tickets"
              :calendar="calendar"
              :review="review"
              :reviews="reviews"
              :vehicles="vehicles"
              :hive="hive"
              :maintenance="maintenance"
              :buzzer="buzzer"
              :scroll-container="$refs.content"
              :elevation="toolbarElevation"
              ref="chat"
              @close-ticket="closeTicket"
              @open-ticket="openTicket"
              @loading="chatLoading"
              @save-ticket="saveTicket"
              @save-calendar="saveCalendar"
              @toggle-alert="toggleAlert"
            />
          </v-window-item>

          <v-window-item>
            <div 
              v-if="!!item" 
              class="item-content info-content text--primary pa-4"
              @click="onContentClick"
            >
              <h6 class="title text-h6 mt-2 mb-8">
                {{ item.title }}
              </h6>
              <p 
                class="text text-body-1 my-6" 
                :inner-html.prop="item.text | fillData(fillableData)" 
              />
              <footer class="text-center my-12">
                <p 
                  class="text--secondary body-2 my-4"
                >
                  Este artigo foi útil?
                </p>
                <v-btn 
                  icon
                  :color="feedback==false ? 'primary' : 'grey'"
                  class="btn-feedback mx-2"
                  @click="setFeedback(false)"
                >
                  <v-icon>{{ feedback==false ? icons.disliked : icons.dislike }}</v-icon>
                </v-btn>
                <v-btn 
                  icon
                  :color="feedback==true ? 'primary' : 'grey'"
                  class="btn-feedback mx-2"
                  @click="setFeedback(true)"
                >
                  <v-icon>{{ feedback==true ? icons.liked : icons.like }}</v-icon>
                </v-btn>
              </footer>
            </div>
          </v-window-item>

          <v-window-item>
            <v-form
              v-model="form.valid"
              ref="form"
              class="help-form pa-4"
            >
              <p class="body-1 mb-8">
                Entre em contato com nosso Suporte Técnico preenchendo o formulário abaixo.
              </p>
              <v-select
                v-model="fields.section.value"
                :items="fields.section.items"
                :loading="fields.section.loading"
                :disabled="fields.section.disabled"
                :label="fields.section.label"
                :rules="fields.section.rules"
                :success="fields.section.success"
                :menu-props="{ bottom: true, offsetY: true }"
                outlined
                color="secondary"
                class="control"
              />
              <v-select
                v-model="fields.category.value"
                :items="fields.category.items"
                :loading="fields.category.loading"
                :disabled="fields.category.disabled"
                :label="fields.category.label"
                :rules="fields.category.rules"
                :success="fields.category.success"
                :menu-props="{ bottom: true, offsetY: true }"
                outlined
                color="secondary"
                class="control"
              />
              <v-textarea
                v-model="fields.message.value"
                :loading="fields.message.loading"
                :disabled="fields.message.disabled"
                :label="fields.message.label"
                :rules="fields.message.rules"
                :success="fields.message.success"
                outlined
                color="secondary"
                class="control"
              >
              </v-textarea>
            </v-form>
          </v-window-item>
        </v-window>
        <!-- <v-divider class="ma-0" /> -->
      </v-card-text>
      
      <v-scroll-y-transition>
        <v-card-actions 
          v-if="footer.toggle"
          class="help-footer"
        >
          <v-btn 
            large
            block
            text
            color="secondary"
            class="btn-cta"
            :loading="footer.cta.loading"
            :disabled="footer.cta.disabled"
            @click="footerAction"
          >
            {{ formButtonLabel }}
          </v-btn>
        </v-card-actions>
      </v-scroll-y-transition>
    </v-card>
  </v-dialog>
</template>

<style>

  #help.sheet {
    background: var(--mobees-black);
  }

  #help .v-toolbar {
    z-index: 3;
  }

  .help-content {
    max-height: calc(100vh - 56px);
  }

  .help-content .ticket-list {
    border: 1px solid var(--v-secondary-darken2) !important;
  }
  .help-content .list-item {
    border-top: 1px solid var(--v-grey-darken2);
  }

  .help-content .item-title {
    overflow: auto;
    white-space: initial;
    line-height: 1.4;
    margin-bottom: 8px;
  }

  #help .section-card .title {
    line-height: 1.125rem;
    letter-spacing: .0875rem !important;
    word-break: normal;
  }

  #help .item-content .title {
    line-height: 1.75rem;
  }

  #help .item-content .text {
    line-height: 1.625rem;
    letter-spacing: 0.0175rem !important;
  }
  #help .item-content.info-content .text p {
    margin-top: 1rem;
  }

  #help .help-content a {
    color: var(--v-accent-base);
    text-decoration: none;
  }

  #help .help-footer {
    /* border-top: 1px solid var(--v-grey-darken3); */
  }
  


</style>

<script>

  // Icons
  import {
    mdiClose,
    mdiChevronLeft,
    mdiChatOutline,
    mdiChevronRight,
    mdiThumbDownOutline,
    mdiThumbUpOutline,
    mdiThumbDown,
    mdiThumbUp
  } from '@mdi/js'

  // Utilities
  import services from '@/services.js'
  import { get, sync } from 'vuex-pathify'
  import { Scroll } from 'vuetify/lib/directives';
  import _ from 'lodash';
  const moment = require('moment');


  export default {
    name: 'Help',

    components: {
      mChatbot: () => import('@/components/mChatbot'),
      mBenefits: () => import('@/views/help/Benefits'),
      Events: () => import('@/views/help/Events'),
      mAlerts: () => import('@/components/mAlerts'),
    },

    props: {
      toggle: {
        type: Boolean,
        default: false
      },
      content: {
        type: Object,
        default: () => null
      },
      alerts: {
        type: Array,
        default: () => []
      },
      reviews: {
        type: Object,
        default: () => {
          return {
            history: [],
            reviewedAt: '',
            reviewedBefore: '',
          }
        }
      },
      hive: {
        type: Object,
        default: () => null
      },
    },

    data: () => ({
      icons: {
        back: mdiChevronLeft,
        close: mdiClose,
        send: mdiChatOutline,
        arrow: mdiChevronRight,
        like: mdiThumbUpOutline,
        dislike: mdiThumbDownOutline,
        liked: mdiThumbUp,
        disliked: mdiThumbDown,
      },
      offsetTop: 0,
      level: 0,
      selected: null,
      context: null,
      section: null,
      event: null,
      feedback: null,
      fields: {
        section: {
          value: null,
          default: null,
          toggle: true,
          loading: false,
          disabled: false,
          label: 'Assunto',
          success: false,
          rules: [v => v!=null || 'Campo obrigatório'],
          items: []
        },
        category: {
          value: null,
          default: null,
          toggle: true,
          loading: false,
          disabled: false,
          label: 'Categoria',
          success: false,
          rules: [v => v!=null || 'Campo obrigatório'],
          items: [
            {
              text: 'Problema Técnico',
              value: 'Issue',
            },
            {
              text: 'Bug no App',
              value: 'Bug',
            },
            {
              text: 'Dúvida',
              value: 'Questionamento',
            },
            {
              text: 'Solicitação',
              value: 'Tarefa',
            },
          ]
        },
        message: {
          value: '',
          default: '',
          toggle: true,
          loading: false,
          disabled: false,
          label: 'Mensagem',
          success: false,
          rules: [v => v!='' || 'Campo obrigatório'],
        },
      },
      form: {
        valid: false,
      },
      chat: {
        loading: false,
        loaded: false,
        ticket: null,
        category: null,
        node: null
      },
      footer: {
        toggle: false,
        cta: {
          toggle: true,
          loading: false,
          disabled: false,
          label: 'Falar com Suporte Mobees',
          success: false,
        }
      },
      priority: {
        access: ['access', null],
        signup: ['signup', 'profile', 'access', 'benefits', null],
        activation: ['goal', 'manual', 'signup', 'access', null],
        profile: ['profile', 'signup', 'access', null],
      },
      dev: process.env.NODE_ENV != 'production',
      storage_url: process.env.VUE_APP_ROOT_STORAGE
    }),

    computed: {
      loading: sync('app/views@help.loading'),
      // context: get('app/views@help.context'),
      // section: sync('app/views@help.section'),
      // selected: sync('app/views@help.item'),
      // ticket: sync('app/views@help.ticket'),
      tickets: sync('app/views@help.tickets'),
      events: sync('events/data@items'),
      calendar: sync('app/views@help.calendar'),
      vehicles: sync('app/views@help.vehicles'),
      advertising: sync('app/views@help.advertising'),
      helpVersion: get('app/views@help.version'),
      notifications: sync('app/views@notifications.items'),
      onboarding: sync('app/views@onboarding'),
      tos: sync('app/views@tos.toggle'),
      privacy: sync('app/views@privacy.toggle'),
      goal: sync('app/goal'),
      buzzer: get('app/buzzer'),
      help: get('help'),
      user: get('user'),
      toast: sync('app/toast'),
      version: sync('app/version'),

      title () {
        const title = this.level==3 ? 
          'Falar com Suporte' : 
          this.sections != null && this.section != null ? 
            this.sections[this.section].type=='issue' ? 
              'Ajuda: Chat' : 
            this.sections[this.section].type=='page' ? 
              this.sections[this.section].title : 
              'Ajuda: '+this.sections[this.section].title : 
            'Ajuda';
        console.log(title);
        return title;
      },

      sections () {
        const list = typeof this.content != undefined ? _.keys(this.content) : {};
        const status = this.user.status;
        let order = this.user.status != 'ACT' ? this.priority[this.context] : list;
        order = _.indexOf(order, null)==-1 ? _.union(order, list) : _.without(order, null);
        let sections = {};
        _.each(order, s => {
          sections[s] = this.content[s];
        });
        if (_.has(sections, 'benefits')) {
          _.each(sections.benefits.cards, benefit => {
            const section = this.content[benefit.key];
            const isTarget = (!_.has(section, 'targetStatus')||_.indexOf(section.targetStatus, status)>=0);
            const isValid = (!_.has(section, 'startsAt')||moment().isAfter(section.startsAt))&&(!_.has(section, 'expiresAt')||moment().isBefore(section.expiresAt));
            if (isTarget&&isValid) {
              sections[benefit.key] = section;
            }else if (_.has(sections, benefit.key)) {
              sections = _.omit(sections, benefit.key);
            }
          });
        }
        return sections;
      },

      sectionList () {
        const sections = _.omitBy(this.sections, s => _.has(s, 'hidden') && s.hidden);
        // console.log(sections);
        return sections;
      },

      items () {
        const items = this.section != null ? this.sections[this.section].items : {};
        return items;
      },

      item () {
        const item = this.selected != null ? this.items[this.selected] : null;
        console.log('selected', item);
        return item;
      },

      isPage () {
        return this.section!=null && _.has(this.sections, this.section) && this.sections[this.section].type=='page';
      },

      toolbarElevation () {
        return this.offsetTop >= 16 ? 2 : 0;
      },

      script () {
        const ticket = this.chat.ticket;
        let script = !!ticket && _.has(ticket, 'script') && !!ticket.script ? ticket.script : _.clone(this.sections[this.section].script);
        if (_.has(script, 'requires')) {
          _.each(script.requires, required => {
            _.each(required.nodes, node => {
              if (node=='nodes') {
                _.assign(script[node], _.get(this.sections, `${required.section}.script.${node}`));
              }else{
                script[required.section+'.'+node] = _.get(this.sections, `${required.section}.script.${node}`);
              }
            });
          });
        }
        return script;
      },

      formButtonLabel () {
        let label = '';
        if (this.level==3) {
          label = _.isNil(this.fields.category.value) ?  'Enviar' : 'Enviar '+this.fields.category.value;
        }else if (this.level==2) {
          label = 'Falar com Suporte Mobees';
        }
        return label;
      },

      hasTickets () {
        return _.size(this.openedTickets)>0;
      },

      openedTickets () {
        return _.omitBy(this.tickets, 'closed');
      },

      supportData () {
        const user = this.user;
        const buzzer = this.buzzer;
        const name = user.profile.fullname;
        const from = `${name} <${user.profile.email}>`;
        const partner = user.partner || user.status=='ACT';
        const vehicle = user.profile.vehicle.model + ' ' + user.profile.vehicle.year;
        const city = this.getUserCity(user);
        const faq = !!this.item ? this.item.title : '–';
        const testing = this.dev ? '[TESTE] ' : '';
        const vHelp = _.isNil(this.helpVersion) ? 'local' : JSON.stringify(this.helpVersion);
        const section = this.sections[this.section];
        const { category=null, node=null } = _.clone(this.chat);

        let body = '';
        body += '\n' + (partner ? 'Parceiro' : 'Motorista') + ' *'+name+'*';
        body += '\n';
        body += '\n*Mensagem:* '+this.fields.message.value;
        body += '\n';
        // body += '\nContexto: '+this.context;
        body += '\nTema Ajuda: '+this.fields.section.value;
        body += '\nItem Ajuda: '+ faq;
        body += '\n';
        body += '\nCPF: ' + this.rawCPF(user.cpf);
        body += '\nEmail: '+user.profile.email;
        body += '\nCelular: '+user.profile.phone;
        body += '\nVeículo: '+vehicle;
        body += '\nCidade: '+city;
        body += '\n';
        if (partner) {
          body += '\nBuzzer: \nStatus: ' + buzzer.status + 
            ' \nCache: ' + buzzer.cached + 
            ' \nÚltima impressão: ' + moment(buzzer.timestamp.impression).format('DD/MM HH:mm:ss') + 
            ' \nÚltimo status: ' + moment(buzzer.timestamp.status).format('DD/MM HH:mm:ss') + 
            ' \nCOD: ' + user.profile.buzzer.code + 
            ' \nNR Patrimônio: ' + user.profile.buzzer.number + 
            ' \nID: ' + user.profile.buzzer.id;
          // body += '<br><b>Horas no ar:</b> Total ' + buzzer.status + ' | Fechamento ' + buzzer.code + ' | NR Patrimônio: ' + buzzer.number + ' | ID: ' + buzzer.id;
        }
        body += '\n';
        body += '\nVersão da Ajuda: '+vHelp;
        body += '\nVersão do app: '+user.profile.app_version;
        body += '\nDevice: '+user.profile.os;
        body += '\n';
        body += '\n';

        return {
          cpf: this.rawCPF(user.cpf),
          authTk: user.auth.token,
          assunto: testing + 'Atendimento – '+this.fields.category.value,
          mensagem: body,
          issue: section.title,
          issueType: this.fields.category.value,
          section: this.section,
          props: {
            ...(_.has(section, 'props') ? section.props : {}), 
            ...(category!=null ? { category } : {}), 
            ...(node!=null ? { node } : {}) 
          },
          nodeId: _.has(section, 'id') ? section.id : null,
          events: _.has(section, 'events') ? section.events : {},
          closeable: _.has(section, 'closeable') ? section.closeable : null,
          assignee: _.has(section, 'assignee') ? section.assignee : 'MEL',
          buzzer: user.profile.buzzer.number,
          city
          // to: 'Suporte Mobees <suporte@mobees.com.br>',
          // from: from,
        }
      },

      review () {
        const review = _.find(this.alerts, ['issue', 'review']);
        return review;
      },
      maintenance () {
        const maintenance = _.find(this.alerts, ['issue', 'urgent']);
        return maintenance;
      },

      disabled () {
        return this.chat.loading && this.chat.ticket==null;
      },

      benefitsData () {
        const content = this.content;
        const sections = this.sections;
        const status = this.user.status;
        const benefits = _.has(sections, 'benefits') ? _.clone(sections['benefits']) : null;
        let items = null;
        if (!!benefits) {
          if (this.benefitsFull) {
            items = this.mapBenefits(benefits.content, content, sections, status);
          }else{
            items = _.has(benefits, 'cards') ? _.clone(benefits.cards) : [];
            items = _.reduce(this.mapBenefits(items, content, sections, status), 
              (grouped, item) => {
                grouped[item.expired ? 'expired' : 'available'].push(item);
                return grouped;
              }, { available: [], expired: [] }
            );
          }
        }
        return !!items ? items : {};
      },

      benefitsCta () {
        const sections = this.sections;
        const benefits = _.has(sections, 'benefits') ? sections['benefits'] : {};
        const url = _.has(benefits, 'url') ? benefits.url : null;
        const text = _.has(benefits, 'cta') ? benefits.cta : undefined;
        return { url, text };
      },

      benefitsFull () {
        const sections = this.sections;
        const status = this.user.status;
        const benefits = _.has(sections, 'benefits') ? sections['benefits'] : {};
        const full = _.has(benefits, 'full') && _.indexOf(benefits.full, status)>=0;
        return full;
      },

      isBenefitsEmpty () {
        return _.size(this.benefitsData) == 0;
      },

      isBenefitSelected () {
        const benefits = _.has(this.sections, 'benefits') ? this.benefitsFull ? this.sections.benefits.content : this.sections.benefits.cards : [];
        const keys = _.map(benefits, 'key');
        const isBenefit = _.indexOf(keys, this.section)>=0;
        return isBenefit;
      },

      isEventSelected () {
        const keys = _.has(this.sections, 'events') ? this.sections.benefits.content : [];
        return _.indexOf(keys, this.section)>=0;
      },

      fillableData () {
        const vehicles = _.reduce(_.orderBy(_.clone(this.vehicles), ['model'], ['asc']), (list, vehicle) => {
          switch (vehicle.status) {
            case 0:
              if (_.isEmpty(list.uncompatible)) list.uncompatible = '<ul></ul>';
              list.uncompatible = this.insert('<li>' + vehicle.model + '</li>', list.uncompatible, _.indexOf(list.uncompatible, '</ul>')-4);
              break;
            case 2:
              if (_.isEmpty(list.compatible)) list.compatible = '<ul></ul>';
              list.compatible = this.insert('<li>' + vehicle.model + '</li>', list.compatible, _.indexOf(list.compatible, '</ul>')-4);
              break;
            default:
              if (_.isEmpty(list.pending)) list.pending = '<ul></ul>';
              list.pending = this.insert('<li>' + vehicle.model + '</li>', list.pending, _.indexOf(list.pending, '</ul>')-4);
              break;
          };
          return list;
        }, { uncompatible: '', compatible: '', pending: '' });
        return { vehicles }
      },

      alertsByPriority () {
        const alerts = this.alerts;
        const list = _.groupBy(_.map(alerts, (alert) => {
          return {
            ...alert,
            flag: alert.priority>=50 ? 'high' : 'low'
          }
        }), 'flag');
        const { high=[], low=[] } = list;
        return { high, low };
      },

      appversion () {
        return _.has(this.version, 'native') ? this.version.native+'/'+this.version.web : this.version;
      },
    },

    filters: {
      ticketBrief (messages, sections, section) {
        const script = _.has(sections, section) ? sections[section].script : null
        const last = _.findLast(messages, msg => !_.has(msg, 'hidden') || !msg.hidden)
        const brief = _.isNil(last) ? !!script ? _.first(script.start).content : '...' : last.content;
        return brief;
      },
      ticketAssignee (assignee) {
        return assignee == 'MEL' ? 'Mel' : 'Mel & Suporte';
      },
      fillData (text, data) {
        _.each(data, (d, k) => {
          if (_.isObject(d)) {
            _.each(d, (dd, kk) => {
              text = _.replace(text, new RegExp('{{'+k+'.'+kk+'}}', 'g'), dd);
            })
          }else{
            text = _.replace(text, new RegExp('{{'+k+'}}', 'g'), d);
          }
        });
        return text;
      }
    },

    watch: {
      toggle (b) {
        if (b) {
          this.getSupportTickets();
          const route = this.$route;
          const context = _.get(route.query, 'context', null) || route.name;
          const section = _.get(route.query, 'section', null) || context;
          const sections = this.sections;
          if (_.has(sections, section)) {
            this.context = context;
            this.section = section;
            this.selected = _.get(route.query, 'item', null);
          }else{
            this.context = context || null;
            this.section = null;
          }
          const ticket = _.get(route.query, 'ticket', null);
          if (!!ticket&&_.has(this.tickets, ticket)) {
            this.openTicket(ticket);
          }
          const event = _.get(route.query, 'event', null);
          this.event = event;
        }else{
          // this.section = null;
        }
      },

      section (s, o) {
        let l;
        console.log(s, o);
        
        if (s==null||!_.has(this.sections, s)||this.sections[s].type=='page') {
          l = 0;
          this.ticket = null;
          this.chat.ticket = null;
        }else{
          this.fields.category.value = null;
          this.fields.message.value = '';

          if (this.sections[s].type=='support') {
            if (o==null) {
              this.openForm();
              return false;
            }
          }else{
            if (this.chat.ticket==null) {
              const section = this.sections[s];
              const tickets = _.clone(this.tickets);
              const sectionTickets = _.reduce(_.orderBy(_.filter(tickets, ['section', s]), ['createdAt'], ['desc']), (groups, ticket) => {
                const status = _.get(ticket, 'data.status', null);
                const group = ticket.closed ? 'closed' : !!status ? 'open' : 'pending';
                groups[group].push(ticket);
                return groups;
              }, { open: [], pending: [], closed: []});
              const useTicket = ((!_.has(section, 'multiple') || !section.multiple) && !_.isEmpty(sectionTickets.open)) || !_.isEmpty(sectionTickets.pending) ? 
                !_.isEmpty(sectionTickets.pending) ? _.first(sectionTickets.pending) : _.first(sectionTickets.open) : 
                !_.isEmpty(sectionTickets.closed) && _.has(section, 'props') && _.has(section.props, 'multiple') && !section.props.multiple ? _.first(sectionTickets.closed) : undefined;
              if (section.type=='issue'&&!!useTicket) {
                this.openTicket(useTicket.id);
              }
            }
            this.fields.category.value = _.has(this.sections[s], 'issueType') ? this.sections[s].issueType : null;
            l = 1;
          }
        }
        if (this.selected==null) this.setLevel(l);
      },
      'selected' (i) {
        let l;
        if (i!=null) {
          l = 2;
          this.setLevel(l);
        }
      },
      level (l) {
        if (l==0&&this.section!='benefits') {
          this.section = null;
          this.selected = null;
          this.ticket = null;
        }else if (l==1) {
          this.selected = null;
        }
        if (this.selected==null) {
          this.toggleFooter();
          this.feedback = null;
        }
      },
      'form.valid' (v) {
        this.toggleFooter();
      },
      tickets: {
        deep: true,
        handler (tickets) {
          console.log(tickets);
          if (this.chat.ticket!=null) {
            const ticket = _.assign({}, this.tickets[this.chat.ticket.id]);
            console.log('ticket', ticket);
            this.chat.ticket = _.isEmpty(ticket) ? null : ticket;
          }
        }
      },
      ticket: {
        handler (id) {
          console.log('ticket', id);
          if (!_.isNil(id)) this.openTicket(id);
        }
      }
    },

    methods: {
      ...services,

      setLevel (l) {
        setTimeout(v => v.level = l, 250, this);
      },

      setFeedback (like) {
        this.feedback = like;
        if (!like) this.toggleFooter(!like);
        this.trackEvent('help/'+this.item.title, like ? 'like' : 'dislike');
      },

      toggleFooter () {
        let toggle;
        if (this.level==3) {
          if (this.form.valid) toggle = true;
        }else if (this.level==2) {
          toggle = true;
        }else{
          toggle = false;
        }
        this.footer.toggle = toggle;
      },

      footerAction () {
        // call footer action method
        if (this.level==3) {
          this.sendForm();
        }else{
          this.openForm();
        }
      },

      toggleOnboarding (b) {
        this.onboarding.toggle = b;
      },

      openForm () {
        if (this.user.auth.token!=null) {
          // setup
          this.clearForm();
          this.fillForm();

          // navigate to form
          this.setLevel(3);
        }else{
          this.openEmail();
        }
      },

      fillForm () {
        this.fields.section.items = _.map(this.sections, s => s.title);
        this.fields.section.value = this.section != null ? this.sections[this.section].title : this.sections[this.context].title;

        if (_.has(this.sections[this.section], 'category')) this.fields.category.value = this.sections[this.section].category;
        if (_.has(this.sections[this.section], 'message')) this.fields.message.value = this.sections[this.section].message;
      },

      clearForm () {
        _.each(this.fields, field => {
          field.value = field.default;
        })
      },

      goBack () {
        if (this.level==3) {
          // const selection = this.selection;
          if (this.section==null||this.sections[this.section].type=='support') {
            this.setLevel(0);
          }else if (this.selected==null) {
            this.setLevel(1);
          }else{
            this.levelUp(false);
          }
        }else{
          if (this.isBenefitSelected) {
            this.section = 'benefits';
          }else{
            this.levelUp(false);
          }
        }
      },

      levelUp (b) {
        let l;
        if (b) {
          l = 1;
        }else{
          l = -1;
        }
        this.setLevel(this.level + l);
      },

      close () {
        if (this.level==0) {
          this.toggleView();
          // this.section = null;
          // this.selected = null;
        }else if (this.level==1) {
          this.selected = null;
        }
      },

      toggleSection (key, item=null) {
        this.section = key;
        if (!!item) this.selected = item;
      },

      mapBenefits (items, content, sections, status) {
        return _.filter(_.map(items, (item) => {
          const section = content[item.key];
          const isTarget = (!_.has(section, 'targetStatus')||_.indexOf(section.targetStatus, status)>=0);
          const available = isTarget&&(!_.has(section, 'startsAt')||moment().isAfter(section.startsAt));
          const expired = (isTarget&&!_.has(sections, item.key))||(_.has(section, 'expiresAt')&&moment().isAfter(section.expiresAt));
          const activated = _.find(this.tickets, ticket => {
            let activated = false;
            if (ticket.section==item.key) {
              activated = !ticket.closed||_.has(section, 'props')&&_.has(section.props, 'multiple')&&!section.props.multiple;
            }
            return activated;
          });
          return { ...item, expired, available, activated: activated!==undefined };
        }), ['available', true])
      },

      toggleBenefit (key) {
        if (this.sections[key].type=='link') {
          const uri = this.sections[key].link;
          window.open(uri, '_blank');
        }else{
          this.toggleSection(key);
        }
      },

      toggleTos () {
        // console.log('toggle TOS');
        this.tos = true;
      },
      togglePrivacy () {
        // console.log('toggle PP');
        this.privacy = true;
      },

      onScroll (e) {
        this.offsetTop = e.target.scrollTop;
      },

      cleanText (html) {
        return html.replace(/(<([^>]+)>)/ig, '');
      },

      openEmail () {
        const user = this.user;
        const name = user.profile.fullname ? user.profile.fullname : '';
        const cpf = user.cpf ? this.rawCPF(user.cpf) : '';
        const email = user.profile.email ? user.profile.email : '';

        let body = '';
        body += 'Data: '+moment().format('DD/MM HH:mm');
        body += '\nNome: ' + name;
        body += '\nCPF: ' + cpf;
        body += '\nEmail: ' + email;
        body += '\nCelular: ';
        body += '\nVeículo: ';
        body += '\n';
        body += '\nAssunto: '+this.context;
        body += '\n';
        body += '\nMensagem: ';

        window.location.href = 'mailto:suporte@mobees.com.br?subject='+encodeURIComponent('Atendimento – App')+'&body='+encodeURIComponent(body);
      },

      sendForm () {
        this.footer.cta.loading = true;
        const data = this.supportData;

        console.log(data);

        this.$api({
          url: '/opensupportticket',
            method: 'POST',
            data: data
          })
          .then(response => {
            console.log('sendForm => ',response);
            if(response.data.retorno==200){
              this.toggleToast(
                true,
                'Mensagem enviada! Entraremos em contato em breve. 😊',
                7000,
                true,
              );

              setTimeout(v => {
                v.goBack();
                v.close(false);
              }, 500, this);

              this.trackEvent('support', 'form sent');
            }else if(response.data.retorno==401) {
              this.getout();
              this.toggleToast(
                true,
                'Sua sessão expirou...',
                5000,
                false
              );
            }else{
              this.toggleToast(
                true,
                'Não foi possível enviar sua mensagem. Tente novamente por favor.',
                5000,
                false,
              );
              this.trackEvent('support', 'form send failed');
            }
          })
          .catch(error => {
            this.handleError(error);
          })
          .finally(() => {
            this.footer.cta.loading = false;
          });
      },

      toggleAlert (msg) {
        if (_.isString(msg)) {
          this.toggleToast(
            true,
            msg,
            10000,
            false,
          );
        }else{
          this.toggleToast(
            false,
            '',
            -1,
            false,
          );
        }
      },

      chatLoading (b) {
        this.chat.loading = b;
      },

      saveCalendar (data) {
        this.$set(this, 'calendar', data);
      },

      saveTicket (data) {
        this.tickets = Object.assign(this.tickets, {
          [data.id]: data
        });
        this.openTicket(data.id);
      },

      closeTicket (id) {
        // this.section = null;
        // this.chat.ticket = null;
        if (!_.isNil(id)) {
          const ticket = this.tickets[id]
          ticket.closed = true;
          ticket.closedAt = moment.utc().toISOString();
          this.tickets = Object.assign({}, this.tickets, { [id]: ticket });
        }
      },

      openTicket (id, config=null) {
        if (_.isNil(id)&&config!=null) {
          this.goBack();
          setTimeout(($, config) => {
            $.section = config.section;
            $.chat.category = _.has(config, 'category') ? config.category : null;
            $.chat.node = _.has(config, 'value') ? config.value : null;
            $.chat.ticket = null;
          }, 500, this, config);
        }else{
          const ticket = this.tickets[id];
          this.chat.node = _.has(config, 'node') ? config.node : null;
          this.chat.category = _.has(config, 'category') ? config.node : null;
          this.chat.ticket = _.assign({}, _.isNil(config) ? ticket : _.merge(ticket, config));
          this.section = ticket.section;
          this.fields.category.value = ticket.issueType;
  
          // set notification as read
          const notifications = _.filter(this.notifications, n => _.isNil(n.read)&&(n.reference=='calendar#'+id||n.reference=='ticket#'+id));
          if (notifications.length>0) this.setNotifications(notifications);
        }
      },

      setNotifications (read) {
        const cpf = this.rawCPF(this.user.cpf);
        const token = this.user.auth.token;

        read = _.map(read, n => {
          return { id: n.id, read: moment.utc().format('YYYY-MM-DD HH:mm:ss') }
        })

        this.$api
          .post('/setdrivernotifications/'+cpf+'/'+token+'/?format=json', read)
          .then(response => {
            console.log('setNotifications => ', response);
            if (response.data.retorno!=401) {
              const notifications = _.keyBy(read, 'id');
              this.notifications = _.merge({}, this.notifications, notifications);
            }
          })
      },

      async getSupportTickets () {
        if (!this.user.cpf||!this.user.auth.token) return;
        this.loading = true;
        this.chat.loading = true;

        const data = {
          cpf: this.rawCPF(this.user.cpf),
          authTk: this.user.auth.token,
          history: true
        }

        this.$api({
          url: '/gettickets',
            method: 'GET',
            params: data
          })
          .then(async (response) => {
            console.log('getSupportTickets => ',response);
            if(response.data.retorno==200){
              this.processTickets(_.reject(response.data.tickets, t => {
                return !this.dev&&_.startsWith(t.fields.summary, '[TESTE]');
              }));
              this.chat.loaded = true;
              this.chat.loading = false;
              this.loading = false;
            }
          })
          .catch(error => {
            this.toggleAlert('Aguardando resposta do sistema... ⏳');
            setTimeout(($) => {
              $.getSupportTickets();
            }, 7000, this);
            this.handleError(error, false);
          })
          .finally(() => {
          });
      },

      async processTickets (data) {
        let cache = {};
        const regSection = /\{\{<section:(.*?)>\}\}/;
        const regCategory = /\{\{<category:(.*?)>\}\}/;
        const regMessages = /\{\{<messages:(.*?)>\}\}/;
        const tickets = data;
        for await (let t of tickets) {
          const assignee = t.fields.assignee!=null ? t.fields.assignee.displayName.split(' ')[0].slice(0,3).toUpperCase() : 'Suporte';
          // new log process
          const log = _.has(t.fields, 'customfield_10044') ? JSON.parse(t.fields['customfield_10044']) : '';
          const hasLog = !_.isEmpty(log);
          const closed = t.fields.status.statusCategory.key=='done';
          if (hasLog) {
            const storage = this.storage_url + '/infodrivers/cpf=' + this.rawCPF(this.user.cpf);
            const messages = _.map(JSON.parse(log.messages), msg => {
              if (msg.content.indexOf('@')>=0) msg.content = msg.content.replace(/\(\@(.*?)\)/gim, (match, $1) => `(${storage}/${$1})`);
              return msg;
            });
            cache[t.id] = {
              id: t.id,
              key: t.key,
              assignee,
              summary: t.fields.summary,
              messages: messages,
              section: log.section,
              category: log.category,
              data: log.data,
              critical: _.has(log, 'critical') ? log.critical : null,
              script: _.has(log, 'script') ? log.script : null,
              createdAt: t.fields.created,
              closed,
              closedAt: closed ? t.fields.updated : null
            }
          }else{
            // old log/message process
            if (regSection.test(t.fields.description)) {
              let category = t.fields.description.match(regCategory);
              cache[t.id] = {
                id: t.id,
                key: t.key,
                summary: t.fields.summary,
                section: t.fields.description.match(regSection)[1], 
                category: _.isNil(category) ? null : JSON.parse(category[1]),
                assignee: t.fields.assignee.displayName.split(' ')[0].slice(0,3).toUpperCase(),
                messages: JSON.parse(t.fields.description.match(regMessages)[1])
              };
            }else{
              cache[t.id] = {
                id: t.id,
                key: t.key,
                summary: t.fields.summary,
                section: 'general', 
                category: null,
                assignee: 'SUP',
                messages: [{
                  interaction: 'message',
                  type: 'text',
                  content: t.fields.description==null ? '' : t.fields.description.replace(/\\/g, ''),
                  human: true,
                  next: [],
                }]
              };
            }
            cache[t.id].createdAt = t.fields.created;
            cache[t.id].closedAt = closed ? t.fields.updated : null
            cache[t.id].closed = closed;
          }
          const history = cache[t.id].closed ? [] : await this.getTicketHistory(t.id);

          if (hasLog) {
            cache[t.id].messages = _.concat(cache[t.id].messages, _.map(_.filter(history, h =>{
              return h.public && _.isNil(_.find(cache[t.id].messages, m => {
                  return m.content == h.body.replace(/\\/g, '');
                }));
              }), m => {
                console.log(m.created.iso8601);
                return {
                  interaction: 'message',
                  type: 'text',
                  content: m.body.replace(/\\/g, ''),
                  timestamp: m.created.iso8601,
                  support: true,
                }
            }));

            // rebranch through Support input
            const regBranch = /<section:(.*?)>/;
            let newBranch = _.last(history);
            newBranch = !_.isNil(newBranch) && regBranch.test(newBranch.body) ? newBranch.body.match(regBranch)[1] : null;
            if (!_.isNil(newBranch)) {
              cache[t.id].section = _.trim(newBranch);
              cache[t.id].critical = null;
              cache[t.id].messages.pop();
              cache[t.id].messages.push({
                interaction: 'message',
                type: 'text',
                delay: 1000,
                content: 'Opa! Mel aqui falando ☺️',
                assignee: 'MEL',
                next: this.sections[newBranch].script.start
              });
            }
          }else{
            let messages = [];
            if (history.length>0) {
              messages = _.concat(cache[t.id].messages, ..._.map(history, m => {
                const bot = regMessages.test(m.body);
                return bot ? JSON.parse(m.body.match(regMessages)[1]) : [{
                  interaction: 'message',
                  type: 'text',
                  content: m.body.replace(/\\/g, ''),
                  support: true,
                }];
              }));
              cache[t.id].messages = messages;
            }

            if (_.has(this.tickets, t.id)&&_.has(this.tickets[t.id], 'data')&&_.has(this.tickets[t.id].data, 'temp')) {
              // persist local temp data
              cache[t.id].data = Object.assign(this.tickets[t.id].data, cache[t.id].data);
            }
          }
        }
        this.tickets = Object.assign({}, _.mapKeys(cache, t => t.id));
        this.getCalendarSlot();
      },

      getTicketHistory (id) {
        return new Promise((resolve, reject) => {

          const data = {
            cpf: this.rawCPF(this.user.cpf),
            authTk: this.user.auth.token,
            issueId: id,
          }

          this.$api({
            url: '/getticketcomments',
              method: 'GET',
              params: data
            })
            .then(response => {
              console.log('getTicketHistory => ',response);
              resolve(response.data.values);

              this.trackEvent('support', 'chatbot history loaded');
            })
            .catch(error => {
              this.handleError(error, false);
              reject(null);
            })
            .finally(() => {
            });
        });
      },

      getCalendarSlot () {
        const tickets = this.openedTickets;
        const hive = this.hive;

        let calendar = _.reduce(tickets, (schedule, ticket) => {
          if (_.has(ticket, 'data')&&_.has(ticket.data, 'calendar')) {
            const calendar = ticket.data.calendar;
            if (!!calendar&&(schedule==null||moment(schedule.start).isAfter(calendar.start))) {
              schedule = { 
                ...calendar,
                issue: ticket.section,
                ticket: ticket.id,
                title: calendar.type=='REV' ? 'Revisão agendada' : calendar.type=='PUB' ? 'Ação Publicitária' : calendar.type=='MAN' ? 'Manutenção agendada' : 'Agendamento',
                text: 'Compareça na Colmeia <b>dia '+moment(calendar.start).format('DD/MM')+' às '+moment(calendar.start).hour()+'h</b><br><a href="'+hive.uri+'" target="_blank">'+hive.address+'</a>. <br>Se precisar, clique aqui para reagendar.'
              };
            }
          }
          return schedule;
        }, null);
        if (!!calendar) {
          this.$set(this, 'calendar', calendar);
        }else{
          const cpf = this.rawCPF(this.user.cpf);
          const token = this.user.auth.token;
          const $ = this;
          // const key = this.getCalendarKey(this.user);
  
          this.$api.get('/getslotcpf/'+cpf+'/'+token)
            .then(response => {
              console.log('getCalendarSlot => ',response);
              const slot = response.data.eventoCpf;
              let calendar = null;
              if (_.isNil(slot)) {
                $.calendar = null;
                return;
              }
              
              const openTickets = _.omitBy($.tickets, 'closed');
              const ticket = _.find(openTickets, t => {
                return (_.has(t.data, 'slot')&&t.data.slot==slot.id)||_.some(t.messages, m => m.interaction == 'calendar' && m.value.type==slot.tipo);
              });
  
              calendar = {
                type: slot.tipo,
              };
              calendar['id'] = slot.id;
              calendar['start'] = moment(slot.start.dateTime).format('YYYY-MM-DD HH:mm');
              calendar['end'] = moment(slot.end.dateTime).format('YYYY-MM-DD HH:mm');
              calendar['issue'] = _.isNil(ticket) ? 'reschedule' : ticket.section;
              calendar['ticket'] = _.isNil(ticket) ? null : ticket.id;
              calendar['title'] = calendar.type=='REV' ? 'Revisão agendada' : calendar.type=='PUB' ? 'Ação Publicitária' : calendar.type=='MAN' ? 'Manutenção agendada' : 'Agendamento';
              const start = moment(calendar.start);
              calendar['text'] = 'Compareça na Colmeia <b>dia '+start.format('DD/MM')+' às '+start.hour()+'h</b><br><a href="'+hive.uri+'" target="_blank">'+hive.address+'</a>. <br>Se precisar, clique aqui para reagendar.';
              if (!_.isNil(calendar)) {
                this.$set(this, 'calendar', calendar);
              }
            })
            .catch(error => {
              this.handleError(error);
            })
            .finally(() => {
            });
        }
      },

      getCalendarSlots (type) {
        const cpf = this.rawCPF(this.user.cpf);
        const token = this.user.auth.token;
        // const key = this.getCalendarKey(this.user);

        this.$api.get('/getslotscalendar/'+cpf+'/'+token+'/'+type)
          .then(response => {
            console.log('getCalendarSlots => ',response);
            if(response.data.retorno==200){
              if (type=='PUB') this.advertising.toggle = response.data.eventosLivres.length>0;

              this.trackEvent('help', 'get calendar slots ok');
            }else{
              this.trackEvent('help', 'get calendar slots failed');
            }
          })
          .catch(error => {
            this.handleError(error);
          })
          .finally(() => {
          });
      },

      getVehicles () {
        this.$api
          .get('/driver/vehicles/?format=json')
          .then(response => {
            console.log('getVehicles => ', response);
            const data = response.data;
            if (data.length>0) {
              this.vehicles = _.map(data, v => { 
                return { model: v.veiculo, status: v.status } 
              });
            }
          })
          .catch(error => {
            this.handleError(error, false);
          })
          .finally(() => {
          });
      },

      onContentClick (e) {
        if ('popup' in e.target.dataset) {
          e.preventDefault();
          const url = ('file' in e.target.dataset ? this.getAppRoot()+'/file?url=' : '') + e.target.href;
          this.popup(url);
        }
      },

      visibilityChange (evt, hidden) {
        // check status
        if (!hidden&&(this.hasTickets&&this.chat.ticket!=null)&&!this.loading) {
          this.getSupportTickets();
        }
      },

      // scrollTo () {
      //   this.$vuetify.goTo(0, {
      //     container: this.$refs.content,
      //     duration: 500,
      //     offset: 0,
      //     easing: 'easeInOutCubic'
      //   });
      // }

    },

    mounted () {
      this.section = null;
      this.ticket = null;
      if (this.user.auth.status) {
        this.getSupportTickets();
        this.getCalendarSlots('PUB');
      }
      this.getVehicles();
    },

    directives: {
      Scroll,
    },
  }
</script>
